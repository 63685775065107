<template>
  <CRow>
    <CCol col="12" :lg="master ? 12 : 8">
      <CToaster :autohide="8000">
        <template v-for="(alert, index) in alerts">
          <CToast :show="true" :color="alert.type" :key="index"> {{ $t(alert.message) }} </CToast>
        </template>
      </CToaster>

      <CCard no-header :accentColor="isFormDirty ? (isFormValid ? 'success' : 'danger') : ''">
        <CCardBody>
          <h3>{{ form.id ? $t('Edit contact') + ` id: ${form.id}` : $t('Create contact') }}</h3>
          <CForm autocomplete="off" @keydown="clearErrors($event.target.name || 'test')">
            <ACard title="Name">
              <AInput name="name" label="Name" v-model="form.name" placeholder="Full name" :isValid="isValid('name')" :errors="getErrors('name')" />
            </ACard>

            <ACard title="Identification">
              <AMultiSelect name="identification_type_id" :horizontal="{ input: 'col-sm-5', label: 'col-sm-3' }" label="Identification type" v-model="form.identification_type_id" :options="lists.identification_types" :isValid="isValid('identification_type_id')" :errors="getErrors('identification_type_id')" />
              <AMaskedInput name="identification" :horizontal="{ input: 'col-sm-5' }" label="Identification" placeholder="Representative's identification" maxLength="14" v-model="form.identification" :mask="form.identification_type_id === 31 ? 'NIT' : 'ID'" :isValid="isValid('identification')" :errors="getErrors('identification')" />
            </ACard>

            <ACard title="Phones">
              <ContactPhones :items="form.phones" label="Phones" :isValid="isValid" :errors="getErrors" @removeElement="value => (form.remove_phones = value)" @arrayChanged="value => (form.phones = value)" />
            </ACard>

            <ACard title="Emails">
              <ContactEmails :items="form.emails" label="Emails" :isValid="isValid" :errors="getErrors" @removeElement="value => (form.remove_emails = value)" @arrayChanged="value => (form.emails = value)" />
            </ACard>

            <CCard v-if="form.buildings && form.buildings.length" border-color="danger">
              <CCardHeader>{{ $t('Warning') }}</CCardHeader>
              <CCardBody>
                <p>
                  El contacto que se encuentra editando está relacionado a <b>{{ form.buildings.length }} Apartamento{{ form.buildings.length > 1 ? 's' : '' }}</b
                  >. Al realizar cambios sobre este contacto, estaría afectando la información del propietario para estos inmuebles:
                </p>

                <div v-for="building of form.buildings" :key="'_B' + building.id">
                  <b>{{ building.type.name }} {{ building.unit.name }}-{{ building.code }}</b>
                </div>
                <br />

                <p>Si desea realizar el cambio de manera especifica para un solo inmueble, debe realizar el cambio de propietario para el inmueble requerido.</p>
              </CCardBody>
            </CCard>
          </CForm>
          <CCardFooter>
            <CButton class="mr-2" :color="form.id ? 'info' : 'primary'" adisabled="!isFormValid" @click="submit">{{ $t(form.id ? 'Save' : 'Create') }}</CButton>
            <CButton color="secondary" @click="onClose">{{ $t(master ? 'Cancel' : 'Back') }}</CButton>
          </CCardFooter>
        </CCardBody>
      </CCard>
    </CCol>

    <!-- // TODO: Debug view... create a global component -->
    <CCol v-if="DEBUG && debug !== false" col="12">
      <pre>{{ form }}</pre>
      <div class="summary text-red" v-if="$v.form.$error">
        Form has errors
        <pre>{{ showErrors }}</pre>
      </div>
    </CCol>
  </CRow>
</template>

<script>
import formMixin from '@/app/views/_mixins/form-mixin'
import ContactPhones from './elements/ContactPhones'
import ContactEmails from './elements/ContactEmails'

import { DEBUG } from '@/config/config'

export default {
  name: 'ContactForm',
  mixins: [formMixin],
  components: {
    ContactPhones,
    ContactEmails
  },
  props: {
    debug: Boolean,
    master: Boolean
  },
  data: () => {
    return {
      DEBUG: DEBUG, // TODO: Global or in Helper

      // DATA
      form: { id: 0, phones: [], emails: [] },

      // Extra
      lists: {
        identification_types: []
      },

      // Helpers
      alerts: [],
      objects: {},
      validators: {}
    }
  },
  created() {
    this.form.id = this.master ? 0 : this.$route.params.id || 0
    this.getData()
  },
  methods: {
    onClose() {
      if (this.master) {
        this.$emit('parentHide')
        this.onResetForm()
      } else this.$router.go(-1)
    },
    onResetForm() {
      this.form = { id: 0, phones: [], emails: [] }
    },
    getData() {
      const self = this
      self.$http
        .get('admin/building_contacts' + (self.form.id ? `/${self.form.id}/edit` : '/create'), { _lists: 'identification_types', _with: 'phones,emails' })
        .then(response => {
          self.parseData(response.data)
        })
        .catch(error => {
          console.error(error)
          self.showAlert(`There was an error.`, 'danger')
          //self.goBack() // TODO: Alert
          //self.$router.push({ path: 'login' })
        })
    },

    // Parse Extra and Related data
    parseData(data) {
      this.form = data.building_contact ? data.building_contact : { id: 0 }
      this.lists = data._lists || {}

      this.parseView()
      this.parsePhones()
      this.parseEmails()

      this.validators = {
        main: data._validation || {},
        extra: data._extra_validation || {}
      }

      // enable lastone empty
      this.validators.extra.phones = JSON.parse(JSON.stringify(this.validators.extra.phones).replace('required', 'nullable'))
      this.validators.extra.emails = JSON.parse(JSON.stringify(this.validators.extra.emails).replace('required', 'nullable'))

      if (_.isEmpty(this.$v.$form)) {
        this.parseValidator(data._validation, data._messages, true)
        this.injectValidator('phones', 0)
        this.injectValidator('emails', 0)
      }

      //if (this.form.id) this.forcedSteps()
    },

    // Parsing

    parseView() {
      const options = [
        { click: this.submit, class: 'mr-2 btn-' + (this.form.id ? 'info' : 'primary'), content: this.$t(this.form.id ? 'Save' : 'Create') }, //disabled: this.isFormValid },
        { click: this.onClose, class: 'btn-secondary', content: this.$t('Back') }
      ]
      this.$store.dispatch('setTopActions', { [(this.form.id ? 'Edit' : 'Create') + 'Contact']: options })
    },
    parsePhones() {
      this.form.phones = this.form.phones || []
      if (!this.form.phones.length) this.form.phones.push({ id: 0, phone: '' })
    },
    parseEmails() {
      this.form.emails = this.form.emails || []
      if (!this.form.emails.length) this.form.emails.push({ id: 0, email: '' })
    },

    // SUBMIT

    submit() {
      this.$v.form.$touch()
      if (this.$v.form.$error) return

      console.log(this.form)

      const self = this
      self.form = self.stripNumbers(self.form, ['identification'])

      console.log(self.form)

      // TODO: clean phone numbers self.form = self.stripNumbers(self.form, ['phone'])
      self.$http[self.form.id ? 'put' : 'post']('admin/building_contacts', self.form) // TODO: on service ?
        .then(response => {
          if (this.master) {
            this.$emit('masterUpdated', { url: 'admin/building_contacts', entities: 'building_contacts', entity: response.data.building_contact })
          } else {
            self.$router.replace(`/admin/property/building/contacts/${response.data.building_contact.id}/edit`).catch(() => {})
          }
          self.showAlert(`Contact ${self.form.id ? 'saved' : 'created'}.`)
          self.parseData(response.data)
        })
        .catch(error => {
          // TODO: move to form helper ?
          //if (error && error.status === 422) {
          if (error.response && error.response.status === 422) {
            console.log('parseInputErrors')
            self.setErrors(error.response.data.errors || {})
          }
          if (error.response?.data?.message == 'The given data was invalid.') {
            for (const key in error.response.data.errors) {
              if (error.response.data.errors[key]) {
                self.message += error.response.data.errors[key][0] + '  '
              }
            }
          } else {
            console.error(error)
            self.showAlert(`There was an error ${self.form.id ? 'saving' : 'creating'} the contact.`, 'danger')
            //self.goBack() // TODO: login ?
          }
        })
    }
  }
}
</script>
