<template>
  <div>
    <div v-for="(item, index) of state" :key="'_E' + index" class="row no-gutters mb-3">
      <CCol col="3" />
      <CCol col="9">
        <div class="input-group">
          <!-- // TODO: Check v-lowercase -->
          <input ref="input" class="form-control" av-lowercase :class="{ 'is-invalid': !isItemValid(index) }" :name="itemName(index)" v-model="item.email" :placeholder="$t('Email')" @change="onChange" />
          <div v-if="index >= 0" class="input-group-append">
            <button type="button" role="button" class="input-group-text" @click="removeItem(index)"><CIcon name="cil-x" /></button>
          </div>
          <template v-if="!isItemValid(index)">
            <div v-for="(error, index) in itemErrors(index)" :key="index" class="invalid-feedback">{{ error.message }}</div>
          </template>
        </div>
      </CCol>
    </div>
    <div class="text-right">
      <CButton :disabled="!canAdd()" class="text-right" @click="addItem()"><CIcon name="cil-plus"/></CButton>
    </div>
    <div v-if="!!duplicates.length" class="text-danger">{{ `Existen elementos duplicados [${duplicates.join(', ')}]` }}</div>
  </div>
</template>

<script>
import * as utils from '@/app/_utils/global-utils'

export default {
  name: 'ContactEmails',
  inheritAttrs: true,
  watch: {
    items(val) {
      this.state = val
    }
  },
  props: {
    items: {
      type: Array,
      required: true
    },

    isValid: {
      type: Function,
      required: true
    },
    errors: {
      type: Function,
      required: true
    }
  },
  computed: {
    name() {
      return this.$attrs.name || this.$attrs.id || this.$vnode.data.model.expression.split('.').pop()
    },
    last() {
      return this.state.length - 1
    },
    duplicates() {
      return utils.duplicates(this.state, 'email')
    }
  },
  data() {
    return {
      state: [], // this.items,
      item_name: 'emails.$each.', // `${this.name}.$each.`
      remove_ids: []
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.$set(this, 'state', this.items || [])
      if (!this.state || !this.state.length) this.addItem()
    })
  },
  methods: {
    canAdd() {
      if (this.state[this.last] && !this.state[this.last].email) return false
      return !!this.state[this.last] && ![...this.errors(this.itemName(this.last))].length
    },
    addItem() {
      this.state.push({ id: 0, email: '' })
      this.onChange()
    },
    removeItem(index) {
      if (this.state[index].id) {
        this.remove_ids.push(this.state[index].id)
        this.$emit('removeElement', this.remove_ids)
      }
      this.state.splice(index, 1)
      if (!this.state.length) this.addItem()
      this.onChange()
    },
    onChange() {
      for (const state of this.state) {
        state.email = state.email.toLowerCase()
      }

      this.$emit('arrayChanged', this.state)
    },

    itemName(index) {
      return `emails.$each.${index}.email`
    },
    isItemValid(index) {
      return this.isValid(this.itemName(index))
    },
    itemErrors(index) {
      return this.errors(this.itemName(index)) || []
    }
  }
}
</script>

<style lang="scss">
@import '@/assets/scss/style';

.control-helper {
  color: $input-placeholder-color;
  font-size: 0.9em;
  padding-top: 7px;
}

.invalid-feedback {
  display: block;
}
</style>
